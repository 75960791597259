import React from "react";
import "./home.css";
import GlowButtons from "./GlowButtons";
import GlassPersoBox from "./GlassPersoBox";


function PersoDetails(){
    return(
        <div className="home_bg">
            <GlowButtons now={1} />

            <GlassPersoBox/>




        </div>


    );
}
export default PersoDetails;