import React from "react";
import "./home.css";
import {Link} from "react-router-dom";
import { useState } from "react";
import GlassInfoBox from "./GlassInfoBox";
import GlowButtons from "./GlowButtons";


function Home(){

    const [style, setStyle] = useState("glow");
    const changeStyle = () => {
    if (style !== "glow") setStyle("glow");
    else setStyle("dull");
    }

    return(
        
        <div className="home_bg">

        
        <GlowButtons/>
        <GlassInfoBox/>



       </div>
        
    
    );
}
export default Home;