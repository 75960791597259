import Home from "./Home";
import PersoDetails from "./PersoDetails";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home/>}>
      </Route>

      <Route path="/perso-details" element={<PersoDetails/>}>
      </Route>

      {/* <Route path="/chatbox" element={<Chatbox/>}>
      </Route>


      <Route path="/search" element={<Searchstud/>}>
      </Route>
      

      <Route path="/myprofile" element={<Myprofile/>}>
      </Route>

      <Route path="/chatid" element={<Chatbox/>}>
      </Route> */}

    </Routes>
  </BrowserRouter>
    </div>
  );
}

export default App;
