import React from "react";
import "./home.css";
import {Link} from "react-router-dom";
import { useState } from "react";
const glowstyle = {
    color:'#255784',
    backgroundColor: '#2196f3',
    boxShadow: '0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #2196f3',
};



function GlowButtons(now){
    let perso= null;
    let edu= null;
    let exp= null;
    let proj= null;
    switch(now){
        case 1:
            perso=glowstyle;
            break;

        case 2:
            edu=glowstyle;
            break;

        case 3:
            exp=glowstyle;
            break;

        case 4:
            proj=glowstyle;
            break;

        }
    return(
        
        <div className="details-button">
        <Link to="/perso-details"><div style={perso} className="perso-details-button" >
            <h1>personal details</h1>
        </div>
        </Link>

        <Link to="/perso-details"><div style={edu} className="edu-details-button">
            <h1>Education</h1>
        </div>
        </Link>

        <Link to="/perso-details"><div style={exp} className="exp-details-button">
            <h1>Experience</h1>
        </div>
        </Link>

        <Link to="/perso-details"><div style={proj} className="proj-details-button">
            <h1>projects</h1>
        </div>
        </Link>
        </div>



    );
}

export default GlowButtons;