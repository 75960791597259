import React from "react";
import "./home.css";



function GlassInfoBox(){
    return(
        <>
         <div className="glass-info-box">
            <h1 className="intro-h1"> Hi,<br/>
                I am Jyotirgamay<br/>
                Computer Science Engineer.
            </h1>
            <div className="mygradimage">
             
             </div>
        </div>
        
        </>
    );
}
export default GlassInfoBox;