import React from "react";
import "./home.css";



function GlassPersoBox(){
    return(
        <>
         <div className="glass-perso-box">
            <h2> Name: Jyotirgamay Kumar
            </h2>
            
        </div>
        
        </>
    );
}
export default GlassPersoBox;